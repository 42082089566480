export const environment = {
  production: true,
  env: "stg",
  gitHash: "f8431b7",
  // showDinamoFunctionalities tiene como finalidad el desplegar las alertas visuales y habilitar las
  // funcionalidades relacionadas con Dinamo. Si se desean activar debe quedar como true
  showDinamoFunctionalities: true,
  // showRedisBtnForDinamo permite desplegar o no el botón Redistribuir Pedido en el modal de detalle
  // para el caso de los pedidos Dínamo que cumplan la regla. Si el parámetro se setea en false
  // el botón no aparecera, independiente de que cumpla con la regla para mostrarse implementada en la lógica
  // principal
  showRedisBtnForDinamo: true,
  // timeoutParams guardará las configuraciones de los tiempos :
  // 1- timeout: de inactividad tolerables en programmate, expresado en min
  // 2- lastCount: del tiempo de conteo final para evitar el cierre de sesión del usuario logueado, expresado en segundos
  timeoutParams: {
    timeout: 5,
    lastCount: 60,
  },
  apiURL: "https://h4l7wy9wl8.execute-api.us-west-2.amazonaws.com",
  optimizerUrl: "https://programadorqa-api.copec.cl/optimize",
};
